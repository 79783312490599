const baseColor = {
  primary: {
    50: '#F1F9EF',
    100: '#D5EDCF',
    200: '#B9E1AF',
    300: '#9CD58E',
    400: '#80C96E',
    500: '#64BD4E',
    600: '#4B9738',
    700: '#366C28',
    800: '#204118',
    900: '#183112',
  },
  secondary: {
    50: '#F3F4FB',
    100: '#DADEF2',
    200: '#B5BCE5',
    300: '#8490D4',
    400: '#5F6FC7',
    500: '#3F51B5',
    600: '#354397',
    700: '#2A3679',
    800: '#1C2450',
    900: '#0E1228',
  },
  success: {
    50: '#ECFDF5',
    100: '#D1FAE5',
    200: '#A7F3D0',
    300: '#6EE7B7',
    400: '#34D399',
    500: '#10B981',
    600: '#059669',
    700: '#047857',
    800: '#065F46',
    900: '#064E3B',
  },
  warning: {
    50: '#FFFBEB',
    100: '#FEF3C7',
    200: '#FDE68A',
    300: '#FCD34D',
    400: '#FBBF24',
    500: '#F59E0B',
    600: '#D97706',
    700: '#B45309',
    800: '#92400E',
    900: '#78350F',
  },
  error: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
  },
  neutral: {
    50: '#F9F9F9',
    100: '#ECEDEC',
    200: '#DADCDA',
    300: '#C1C4C1',
    400: '#AFB3AE',
    500: '#9CA19B',
    600: '#7E847C',
    700: '#616660',
    800: '#444743',
    900: '#1D1F1D',
  },
  'dark-gray': '#111111',
};

module.exports = {
  baseColor,
};
