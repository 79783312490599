import { ReactNode } from 'react';
import { BaseComponent } from '../../model';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import ReactLoading from 'react-loading';
import { theme } from 'tailwind.config';

type BaseButtonComponent = BaseComponent & {
  children?: ReactNode;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  disabled?: boolean;
  type?: 'reset' | 'submit' | 'button';
  loading?: boolean;
};

type BaseButtonIconComponent = BaseButtonComponent & {
  side?: 'left' | 'right';
  customIcon?: ReactNode;
  disabled?: boolean;
};

export const BaseButton = ({
  className,
  children = 'Primary Button',
  onClick = () => null,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  disabled = false,
  type,
  loading = false,
}: BaseButtonComponent) => {
  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      type={type}
      className={`${
        disabled ? '!bg-gray-200' : null
      } ${className} bg-primary-500 hover:bg-primary-600 active:bg-primary-700 h-10 min-w-[140px] rounded-[100px] px-4 py-3 text-sm font-semibold leading-4 text-white`}
    >
      {loading ? (
        <div className="-mt-10 flex justify-center">
          <ReactLoading
            type={'balls'}
            color={theme.extend.colors.primary[300]}
            height={20}
            width={80}
          />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export const BaseIconButton = ({
  className,
  side = 'left',
  children = 'Primary Button',
  customIcon,
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled,
}: BaseButtonIconComponent) => {
  const Icon = (className: string) => {
    return customIcon || <RefreshIcon className={className} />;
  };

  return side === 'left' ? (
    <BaseButton
      onClick={onClick}
      className={`${className} flex`}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {Icon('mr-2')}
      {children}
    </BaseButton>
  ) : (
    <BaseButton
      onClick={onClick}
      className={`${className} flex`}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {Icon('ml-2')}
    </BaseButton>
  );
};
