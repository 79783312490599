const en = {
  translation: {
    imprint: 'Impressum',
    dataProtection: 'Datenschutz',
    aboutUsSmall: 'über uns',
    aboutUs: 'über uns',
    aboutUsTitle: 'about us',
    weAreBeckschulte: 'Wir sind <span class="text-primary-500">Beckschulte<span>',
    aboutUsDescriptionFirst:
      'Wir sind ein Team von Spezialisten und befassen uns seit mehr als 25 Jahren erfolgreich mit der Verwaltung, der Vermietung und dem Verkauf von Wohn- u. Gewerbe-Immobilien im Kreis Unna und dem gesamten Ruhrgebiet. Seriosität, Kompetenz und Engagement sind die Grundlagen unseres Handelns. Besonderen Wert legen wir auf die Qualifikation unserer Mitarbeiter, die über ausgezeichnete, fachspezifische Kenntnisse und Berufserfahrung verfügen. So erwartet Sie bei uns Professionalität verbunden mit Persönlichkeit.',
    aboutUsTeamDescription: 'freundlich – hilfsbereit – kompetent',
    readOurNews: 'Read our News',
    readOurNewsTitleFirst: 'What will happen to house prices in 2022?',
    readOurNewsTitleSecond: 'The 10 most in-demand rural locations for buyers',
    readOurNewsTitleThird: 'What can you buy in the happiest places to live?',
    readOurNewsDescriptionFirst:
      'Are you one of the many home-hunters looking to move this year? We’ve never seen the property market as hectic ...',
    readOurNewsDescriptionSecond:
      'Are you looking to make a life-changing move? If so, where will it be, and what type of home is at the top of your ...',
    readOurNewsDescriptionThird:
      'Our annual Happy at Home Index, now in its 10th year, has uncovered the happiest places to live in Germany ...',
    verwalten: 'Verwalten',
    verwaltenDescription:
      'Seit mehr als 25 Jahren vertrauen unsere Kunden auf unseren Sachverstand und legen die Betreuung ihrer Immobilien in unsere Hände. ',
    vermieten: 'Vermieten',
    rental: 'Vermieten',
    rentalDescription:
      'Mieter und Vermieter haben mit uns einen Partner an Ihrer Seite der Sie engagiert und kompetent betreut.',
    search: 'Suche',
    residentalObject: 'Wohn-Objekte',
    commercialObject: 'Gewerbe-Objekte',
    weOfferYouFollowingObject: 'Wir bieten Ihnen folgende Objekte an',
    page: 'Seite',
    object: 'Objekte',
    verkaufen: 'Verkaufen',
    verkaufenDescription:
      'Käufer und Verkäufer profitieren von unserer langjährigen Erfahrung und unserer professionellen Vorgehensweise.',
    sale: 'Verkaufen',
    saleDescription:
      'Mit uns haben Sie einen kompetenten Partner an Ihrer Seite, der den Verkauf Ihrer Immobilie professionell und engagiert betreibt.',
    certificateHeader: 'Sachverständiger für\nImmobilienbewertung',
    heroMotto: "...weil’s mit uns <br /> besser geht  <span class='text-primary-500'>.</span>",
    contact: 'Kontakt',
    watchTheFilm: 'Film ansehen',
    propertyManagement: 'Immobilien - Verwaltung',
    propertyManagementDescriptionFirst:
      'Die Verwaltung von Immobilien ist eine anspruchsvolle Aufgabe. Sie erfordert Spezialkenntnisse aus unterschiedlichen Bereichen sowie das Zusammenwirken verschiedenster Fachleute. Seit mehr als 25 Jahren vertrauen unsere Kunden auf unseren Sachverstand und legen die Betreuung ihrer Immobilien und den Erhalt dieser Vermögenswerte in unsere Hände. Für unsere Kunden entwickeln wir individuelle Konzepte mit dem Ziel, die Rentabilität und Werthaltigkeit ihrer Immobilien zu sichern und zu verbessern. Fachleute aus der Wohnungswirtschaft, Betriebswirte und Juristen sind Garanten für unsere professionelle Verwaltung.',
    propertyManagementDescriptionSecond:
      'Seit mehr als 20 Jahren vertrauen unsere Kunden auf unseren Sachverstand und legen die Betreuung ihres Eigentums und den Erhalt ihrer Vermögenswerte in unsere Hände. Diese vertrauensvolle Zusammenarbeit ist Grundlage unseres Handelns.',
    propertyManagementDescriptionThird:
      'Fachleute aus der Grundstücks- u. Wohnungswirtschaft, Betriebswirte und juristische Berater sind der Garant für eine professionelle Verwaltung.',
    propertyManagementDescriptionFourth:
      'Für unsere Kunden entwickeln wir individuelle Konzepte mit dem Ziel, die Rentabilität und Werthaltigkeit Ihrer Immobilien zu sichern und zu verbessern. Dabei gehen wir auf spezielle Wünsche unserer Kunden ein und gestalten die Verwaltungstätigkeit übersichtlich und verständlich.',
    propertyManagementDescriptionFifth:
      'Im Folgenden finden Sie unseren Leistungsumfang aus den Bereichen der Miet- u. WEG-Verwaltung. Wenn Sie dazu weitergehende Fragen haben zögern Sie nicht uns anzusprechen.',
    wegAdmin: 'WEG - administration',
    wegAdminDescriptionFirst:
      'Die Verwaltung gemeinschaftlichen Eigentums ist eine anspruchsvolle Aufgabe. Sie erfordert Spezialkenntnisse in kaufmännischer, technischer, rechtlicher und organisatorischer Hinsicht.',
    wegAdminDescriptionSecond:
      'Dabei geht es um den sorgsamen Umgang mit den anvertrauten Vermögenswerten. Insbesondere geht es darum, die Attraktivität und Werthaltigkeit einer Immobilie zu erhalten und zu verbessern.',
    wegAdminDescriptionThird: 'Unser Leistungsumfang',
    wegAdminDescriptionFourth:
      'Wichtig ist die Fähigkeit, unterschiedliche Interessen im Sinne gemeinschaftlicher Ziele auszugleichen.',
    wegAdminDescriptionFifth:
      'Nicht zuletzt geht es darum, die Attraktivität einer Wohnanlage zu erhalten bzw. zu verbessern.',
    wegAdminService: 'Unsere Serviceleistungen für Sie:',
    wegAdminServiceListFirst: 'Erstellung der Hausgeldabrechnung und des Wirtschaftsplans',
    wegAdminServiceListSecond: 'Einberufung / Leitung / Protokollierung der Eigentümerversammlung',
    wegAdminServiceListThird: 'Umsetzung von Beschlüssen',
    wegAdminServiceListFourth: 'Ansprechpartner für Eigentümer und Dritte',
    wegAdminServiceListFifth: '24 Stunden Notdienst',
    wegAdminServiceListSixth: 'Prüfung und Optimierung von Verträgen',
    wegAdminServiceListSeventh:
      'Führung der Objekt- und Finanzbuchhaltung inkl. Mahn- und Klagewesen',
    wegAdminServiceListEight: 'Regelmäßige Begehung der Immobilie',
    wegAdminServiceListNine: 'Anweisung und Kontrolle von Servicekräften',
    wegAdminServiceListTen:
      'Vergabe und Überwachung von Reparatur-Instandhaltungs- und Modernisierungsmaßnahmen',
    wegAdminServiceListEleven: 'Planung von baulichen und gestalterischen Maßnahmen',
    wegAdminServiceListTwelve:
      'Strategische Planung mit dem Ziel, die Aufwands- und Ertragskomponenten zu optimieren',
    wegAdminServiceListThirteen:
      'Vergabe und Überwachung von Reparaturen und Instandhaltungsmaßnahmen',
    wegAdminServiceListFourteen: 'Planung von zukünftigen baulichen und gestalterischen Maßnahmen',
    wegAdminServiceListFifteen:
      'Strategische Planung mit dem Ziel, die Aufwands- und Ertragskomponenten zu optimieren',
    wegAdminServiceListSixteen: 'Wir übernehmen Verantwortung damit Sie entlastet werden.',
    rentalManagement: 'Miet - Verwaltung',
    rentalAdministrator: 'WEG - Verwaltung',
    rentalManagementDescription:
      'Auf Eigentümer von Mietobjekten kommen stetig steigende Anforderungen zu. Insbesondere komplexe rechtliche Anforderungen, die Planung und Begleitung von Instandhaltungs- und Modernisierungsmaßnahmen, die Suche nach solventen Mietern sowie das Kostenmanagement sind für Eigentümer von Mietobjekte eine Herausforderung.',
    rentalManagementService: 'Unser Leistungsumfang',
    objectData: 'Objektdaten',
    doYouWantToRent: 'Sie möchten <span class="text-primary-500">vermieten?</span>',
    rentDescription:
      'Die langfristige Sicherung der Mieterträge steht für die meisten Vermieter im Vordergrund. Deswegen ist es besonders wichtig, passende solvente Mieter zu finden, die mit der Mietsache pfleglich umgehen. Mit uns haben Sie einen kompetenten Partner an Ihrer Seite, der die Vermietung Ihrer Immobilie professionell und engagiert betreibt. Nutzen Sie unsere Erfahrung und sprechen Sie uns an.',
    rentServices: 'Unser Leistungsumfang',
    rentServiceForYouFirst: 'Besichtigung und Erfassung Ihrer Immobilie',
    rentServiceForYouSecond: 'Kompetente Beratung',
    rentServiceForYouThird: 'Erstellung einer ansprechenden Fotoserie',
    rentServiceForYouFourth: 'Gestaltung eines aussagekräftigen Exposés',
    rentServiceForYouFifth: 'Präsentation auf diversen Internetportalen',
    rentServiceForYouSixth: 'Einsatz von Vermietungsschildern',
    rentServiceForYouSeventh: 'Versierte Führung bei Besichtigungen',
    rentServiceForYouEight: 'Bonitätsprüfung der Mietinteressenten / Mieterselbstauskunft',
    rentServiceForYouNine: 'Erstellung aktueller Mietverträge',
    rentServiceForYouTen: 'Abschlussgespräch ',
    rentalManagementServiceListFirst: 'Abschluss und Kündigung von Mietverträgen',
    rentalManagementServiceListSecond: 'Mieterhöhungspotentiale aufdecken und durchsetzen',
    rentalManagementServiceListThird: 'Erstellung der Betriebskosten- und Heizkostenabrechnung',
    rentalManagementServiceListFourth: 'Bereitstellen von Unterlagen für Ihren Steuerberater',
    rentalManagementServiceListFifth:
      'Persönlicher Ansprechpartner für Eigentümer, Mieter und Dritte',
    rentalManagementServiceListSixth: '24 Stunden Notdienst',
    rentalManagementServiceListSeventh: 'Information und Kommunikation über wesentliche Ereignisse',
    rentalManagementServiceListEight: 'Zusammenstellung von Gebäudeunterlagen',
    rentalManagementServiceListNine:
      'Führung der Objekt- und Finanzbuchhaltung inkl. Mahn- und Klagewesen',
    rentalManagementServiceListTen: 'Regelmäßige Begehung der Immobilie',
    rentalManagementServiceListEleven: 'Anweisung und Kontrolle von Servicekräften',
    rentalManagementServiceListTwelve: 'Überwachung des baulichen Zustandes',
    rentalManagementServiceListThirteen:
      'Vergabe und Überwachung von Reparatur-, Instandhaltungs- und Modernisierungsmaßnahmen',
    rentalManagementServiceListFourteen: 'Prüfung und Optimierung bestehender Verträge',
    rentalManagementServiceListFifteen:
      'Strategische Planung mit dem Ziel die Aufwands- und Ertragskomponenten zu optimieren',
    rentalManagementServiceListSixteen:
      'Planung von zukünftigen baulichen und gestalterischen Maßnahmen damit Sie entlastet werden',
    rentalManagementServiceListSeventeen:
      'Strategische Planung mit dem Ziel die Aufwands- und Ertragskomponenten zu optimieren',
    rentalManagementServiceListEighteen: 'Laufende Überwachung des baulichen Zustandes',
    rentalManagementServiceListNineteen: 'Regelmäßige Begehung der Immobilie',
    rentalManagementServiceListTwenty:
      'Anweisung und Kontrolle von Hilfskräften (Hausmeister, Putzfrau, Gärtner)',
    rentalManagementServiceListTwentyOne:
      'Vergabe und Überwachung von Reparaturen und Instandhaltungsmaßnahmen',
    rentalManagementServiceListTwentyTwo: 'Abschluss und Überprüfung von Wartungsverträgen',
    rentalManagementServiceListTwentyThree:
      'Planung von zukünftigen baulichen und gestalterischen Maßnahmen',
    adminMottto: 'Wir übernehmen Verantwortung damit Sie entlastet werden.',
    makeContact: 'Kontakt aufnehmen',
    doYouWantToSell: 'Sie möchten <span class="text-primary-500">verkaufen?</span>',
    sellDescriptionFirst:
      'Sie suchen nach einer realistischen Einschätzung Ihrer Immobilie? <br /> In diesem Fall bieten wir Ihnen kostenlos eine sachkundige Objektbewertung an.',
    sellDescriptionSecond:
      'Erfolgreicher Immobilien-Verkauf setzt fundierte Marktkenntnisse und ein hohes Maß an Erfahrung voraus. <br /> Wir von Beckschulte Immobilien-Management kennen den hiesigen Immobilienmarkt in all seinen Facetten und führen seit vielen Jahren Angebot und Nachfrage erfolgreich zusammen. ',
    sellDescriptionThird:
      'Mit uns haben Sie einen kompetenten Partner an Ihrer Seite, der den Verkauf Ihrer Immobilie professionell und engagiert betreibt. Sollten Sie erwägen jetzt oder in nächster Zeit Ihre Immobilie zu verkaufen, stehen wir Ihnen gerne für ein unverbindliches Gespräch zu Verfügung.',
    sellServices: 'Unser Leistungsumfang',
    sellServiceForYouFirst: 'Besichtigung und Erfassung Ihrer Immobilie',
    sellServiceForYouSecond: 'Erstellung einer sachverständigen Wertermittlung',
    sellServiceForYouThird: 'Kompetente und individuelle Beratung',
    sellServiceForYouFourth: 'Erstellung einer ansprechenden Fotoserie',
    sellServiceForYouFifth: 'Gestaltung eines aussagekräftigen Exposés',
    sellServiceForYouSixth: 'Präsentation auf diversen Internetportalen',
    sellServiceForYouSeventh: 'Einsatz von Verkaufsschildern, Flyern etc.',
    sellServiceForYouEight: 'Zusammenstellung der Verkaufsunterlagen',
    sellServiceForYouNine: 'Ansprache vorgemerkter Interessenten',
    sellServiceForYouTen: 'Versierte Führung bei Besichtigungen',
    sellServiceForYouEleven: 'Vorbereitung des Beurkundungstermins',
    sellServiceForYouTwelve: 'Begleitung der Parteien zum Notartermin',
    financeContact:
      'Nehmen Sie unverbindlich Kontakt zu uns auf <br /> … weil’s mit uns besser geht!',
    purchasePrice: 'Kaufpreis',
    rentPrice: 'Kaltmiete',
    housingCost: 'Hausgeld',
    viewThePrice: 'View the Price',
    expoetAsPdf: 'Kurzexpose als PDF',
    contactPerson: 'Ansprechpartner',
    readMore: 'Read More',
    showLess: 'Show Less',
    imprintProfile: 'André Beckschulte',
    imprintCompanyName: 'André Beckschulte Immobilien-Management',
    imprintCompanyAddress: 'Friedrich-Ebert-Str 76 59425 Unna',
    imprintContactName: 'Ansprechpartner: André Beckschulte',
    becomePartner: 'Become a Partner',
    imprintTelNumber: '0959736637',
    impruntFax: 'Fax: 02303/94237-209',
    imprintEmail: 'BIM@Beckschulte.com',
    imprintEmailDetail: 'USt.-Id-Nr.: 316/5017/1102',
    imprintCopyRight:
      'Erlaubnis nach § 34c Gewerbeordnung durch den Kreis Unna, Friedrich-Ebert-Str. 17, 59425 Unna.',
    imprintCertificate: 'Verbraucherinformation gemäß Verordnung (EU) Nr. 524/2013:',
    imprintContactOne:
      'Im Rahmen der Verordnung über Online-Streitbeilegung zu Verbraucherangelegenheiten steht Ihnen unter',
    imprintContactTwo: 'http://ec.europa.eu/customers/odr/',
    imprintContactThree: 'eine Online-Streitbeilegungsplattform der EU-Kommission zur Verfügung.',
    imprintContactEmailTitle: 'Kontakt per E-Mail:',
    imprintCertificateOne: 'Bildnachweis:',
    imprintCertificateTwo: 'Bildquellen von Bildern aus dem Archiv: www.fotolia.de',
    imprintCertificateThree:
      'Maridav - Fotolia.com, uview - Fotolia.com, Yuri Arcurs - Fotolia.com, aris sanjaya - Fotolia.com, Andrey Armyagov - Fotolia.com, Ilike - Fotolia.com, goodluz - Fotolia.com,photocreo - Fotolia.com , Monkey Business - Fotolia.com, Kzenon - Fotolia.com, pab_map - Fotolia.com',
    dataPrivacyTitle: 'Datenschutzerklärung',
    fieldIsRequired: 'Pflichtfeld',
    invalidEmail: 'In einer E-Mail-Adresse muss ein @-Zeichen enthalten.',
    salutation: 'Anrede',
    pleaseSelect: 'Bitte auswählen',
    firstName: 'Vorname',
    surnameCompany: 'Nachname/Firma',
    address: 'Strasse/Hausnummer',
    zipCode: 'PLZ/Ort',
    email: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    yourQuestion: 'Ihre Frage',
    canCallback: 'Ich bitte um einen Ruckruf.',
    copyToEmail: 'Ich wünsche eine Kopie der von mir eingegebenen Daten per E-Mail.',
    acceptTerm:
      'Hiermit erkläre  ich mich einverstanden, dass meine eingegebenen Daten elektronisch gespeichert und zum Zweck der Kontaktaufnahme verarbeitet und genutzt werden. Mir ist bekannt, dass ich die Einwilligung jederzeit widerrufen kann. Datenschutzrichtlinien',
    send: 'abschicken',
    reset: 'zurücksetzen',
    contactSubTitle:
      'Wir möchten Ihre Anfrage gerne umgehend bearbeiten. Dazu benötigen wir einige Angaben von Ihnen. Das Ausfüllen der mit (*) gekennzeichneten Felder ist in jedem Fall erforderlich.',
    contactOpenClose: 'Öffnungszeit: Mo. - Fr. 08.00 -13.00 Uhr und 14.00 - 17.00 Uhr',
    contactAddress:
      'André Beckschulte Immobilien-Management - Friedrich-Ebert-Str. 76 - 59425 Unna.',
    contactPhone: '02303 / 94 237 - 0 ',
    contactEmail: 'BIM@Beckschulte.com',
    contactUs: 'Kontakt uns',
    imprintTelTitle: 'Tel',
    successful: 'Erfolgreich',
    failed: 'Fehlgeschlagen',
    needToBuy: 'Sie möchten verkaufen?',
    needToRent: 'Sie möchten vermieten?',
    ourTeam: '<span class="text-primary-500">Unser</span> Team',
    ourOffice: '<span class="text-primary-500">Unser</span> Büro',
    seeMore: 'weitere Infos',
    back: 'Back',
    buyerCommission: 'Käufer-Courtage',
    referenceObjects: 'Referenzobjekte',
    certificateMotto:
      'Durch unsere Mitgliedschaft in Berufsverbänden verpflichten wir uns zu <span class="text-primary-500">Qualität und hohen Leistungsstandards.</span>',
    homeAboutUsDescription:
      'Wir sind ein Team von Spezialisten und befassen uns seit mehr als 25 Jahren erfolgreich mit der Verwaltung, der Vermietung und dem Verkauf von Wohn- u. Gewerbe-Immobilien im Kreis Unna und dem gesamten Ruhrgebiet. Seriosität, Kompetenz und Engagement sind die Grundlagen unseres Handelns. Besonderen Wert legen wir auf die Qualifikation unserer Mitarbeiter, die über ausgezeichnete, fachspezifische Kenntnisse und Berufserfahrung verfügen. So erwartet Sie bei uns Professionalität verbunden mit Persönlichkeit.',
    learnMore: 'weitere Infos',
  },
};

export default en;
