export const coverVideoUrl =
  'https://s3.eu-central-1.amazonaws.com/beckschulte.assets/beckschulte-video-cover.mp4';

export const ROUTE_PATH = {
  HOME: '/',
  ADMIN: '/verwalten',
  RENTAL: '/vermieten/',
  SELL: '/verkaufen/',
  ABOUT_US: '/uber-uns/',
  CONTACT: '/kontakt/',
  IMPRINT: '/impressum',
  DATA_PROTECTION: '/datenchutz',
};

export enum EstateStatus {
  SOLD = 'verkauft',
  RENTED = 'vermietet',
}

export enum MainFacilityName {
  ConstructYear = 'Baujahr',
  Space = 'Gewerbefläche',
  Room = 'Anzahl Räume',
  Location = 'Lage der Einheit',
  Floor = 'Anzahl Etagen',
  Unit = 'Anzahl Einheiten',
  Bathroom = 'Bad / WC',
}

export const telephoneNumber = '02303 942370';

export const gredBeckshultePhoneNumber = '02303 94 237-250';

export const PREFIX_PROXY_IMAGE = '//images.weserv.nl/?url=';

export const GENERATE_PDF_URL = `${process.env.NX_CMS_URL}/pdf/generate_pdf.php?object_id=`;
