import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/icons/logoBeckschulte.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { navbarItems, NavStateConfig } from './index';
import { ROUTE_PATH, telephoneNumber } from '../../../constant/utils';
import { phoneNumber } from 'constant/aboutUs';
import { BaseIconButton } from '../button';
import { TertiaryOutlineIconButton } from '../button/Tertiary';
import { cx, css } from '@emotion/css';

const Hamburger = styled.div<{ open: boolean }>`
  user-select: none;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transform: translateY(3px);
  cursor: pointer;

  ${({ open }) => (open ? 'transform: translate(4px, 5px);' : '')}
  > span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  > span:first-child {
    transform-origin: 0% 0%;
  }

  > span:nth-last-child(1) {
    transform-origin: 0% 100%;
  }

  ${({ open }) =>
    open
      ? `
      > span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
      }

      > span:nth-last-child(1) {
        transform: rotate(-45deg) translate(-2px, 1px);
      }

      span:nth-last-child(2){
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
  `
      : ''}
`;

const DesktopNavbar = ({ navState }: { navState: NavStateConfig }) => {
  const {
    isNavOpen,
    setIsNavOpen,
    isScrollDown,
    isTransparentTheme,
    activeNav,
    isHomePage,
    clearHouseName,
  } = navState;

  return (
    <div
      className={cx(
        `${
          isNavOpen || isScrollDown || isTransparentTheme
            ? 'ease bg-primary-500  w-full px-6 transition duration-1000 md:px-8'
            : 'w-full px-6 md:px-8'
        }`,
        css`
          @media screen and (min-width: 1024px) {
            padding: 0 16px !important;
          }
          @media screen and (min-width: 1152px) {
            padding: 0 32px !important;
          }
        `,
      )}
    >
      <div
        id="desktop-navbar"
        className="mx-auto flex h-[90px] w-full flex-row items-center justify-between text-lg text-white lg:max-w-[1144px]"
      >
        <Link to={ROUTE_PATH.HOME}>
          <Logo className="h-[72px] w-[209px]" />
        </Link>
        <div className="flex gap-x-8">
          {navbarItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={`hidden lg:block ${
                activeNav(item.path) ? 'text-primary-100' : 'text-white'
              }`}
              onClick={() => clearHouseName()}
            >
              {item.title}
            </Link>
          ))}
        </div>
        <div className="flex items-center">
          {isHomePage && !isScrollDown ? (
            <BaseIconButton
              onClick={() => window.open(`tel:${phoneNumber}`, '_self')}
              className="mx-6 hidden h-14 w-[186px] items-center justify-center border-white text-lg text-white md:flex lg:mx-0"
              customIcon={<PhoneIcon className="mr-2" />}
            >
              {telephoneNumber}
            </BaseIconButton>
          ) : (
            <TertiaryOutlineIconButton
              onClick={() => window.open(`tel:${phoneNumber}`, '_self')}
              className="mx-6 hidden h-14 w-[186px] items-center justify-center border-white text-lg
          text-white  md:flex lg:mx-0"
              customIcon={<PhoneIcon className="mr-2" />}
            >
              {telephoneNumber}
            </TertiaryOutlineIconButton>
          )}

          <Hamburger
            open={isNavOpen}
            onClick={() => setIsNavOpen(!isNavOpen)}
            className="block lg:hidden"
          >
            <span></span>
            <span></span>
            <span></span>
          </Hamburger>
        </div>
      </div>
    </div>
  );
};

export default DesktopNavbar;
