export const saveEstateFilter = (filterKey: string, categoryName: string) => {
  return sessionStorage.setItem(filterKey, categoryName);
};

export const getEstateFilter = (filterKey: string) => {
  return sessionStorage.getItem(filterKey);
};

export const removeEstateFilter = (filterKey: string) => {
  return sessionStorage.removeItem(filterKey);
};

export const saveNameHouse = (house: string, houseName: string) => {
  return sessionStorage.setItem(house, houseName);
};

export const getNameHouse = (house: string) => {
  return sessionStorage.getItem(house);
};

export const RemoveNameHouse = (house: string) => {
  return sessionStorage.removeItem(house);
};
