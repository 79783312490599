import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'assets/icons/logoFooter.svg';
import { navbarItems } from 'components/navbar';
import { useTranslation } from 'react-i18next';
import { phoneNumber } from 'constant/aboutUs';
import { cx, css } from '@emotion/css';

const MobileFooterMenu = () => {
  const leftSideMenu = navbarItems.filter((_entry, index) => index < navbarItems.length / 2);
  const rightSideMenu = navbarItems.filter((_entry, index) => index >= navbarItems.length / 2);
  return (
    <div id="footer-menu" className="text-neutral-50 flex text-base !leading-[19px] md:!hidden">
      <div className="w-1/2">
        {leftSideMenu.map((entry) => (
          <div key={`footer-menu-${entry.title}`} className="mt-2 cursor-pointer">
            <Link to={entry.path}>{entry.title}</Link>
          </div>
        ))}
      </div>
      <div className="w-1/2">
        {rightSideMenu.map((entry) => (
          <div key={`footer-menu-${entry.title}`} className="mt-2 cursor-pointer">
            <Link to={entry.path}>{entry.title}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

const FooterMenu = () => (
  <div
    id="footer-menu"
    className="text-neutral-50 flex text-base !leading-[19px] md:justify-evenly md:!text-lg md:!leading-[22px]"
  >
    {navbarItems.map((entry) => (
      <div key={`footer-menu-${entry.title}`} className="mt-2 hidden cursor-pointer md:flex">
        <Link to={entry.path}>{entry.title}</Link>
      </div>
    ))}
  </div>
);

const getCopyRightText = () => {
  return `© 2019 - ${new Date().getFullYear()} Beckschulte All rights reserved`;
};

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="w-full">
      <div
        className={cx(
          'bg-neutral-800 text-neutral-500 px-4 pt-8 pb-[32px] text-sm !leading-[17px] md:!px-8 md:!py-8 md:!text-base md:!leading-[19px]',
          css`
            @media screen and (min-width: 1024px) {
              padding: 32px 16px !important;
            }
            @media screen and (min-width: 1152px) {
              padding: 32px 32px !important;
            }
            @media screen and (min-width: 1440px) {
              padding: 0;
            }
          `,
        )}
      >
        <div className="max-w-[920px] lg:mx-auto lg:max-w-[1144px]">
          <div
            id="company-info"
            className="lg:flex lg:justify-between lg:!text-sm lg:!leading-[17px]"
          >
            <Link to={'/'}>
              <LogoIcon />
            </Link>
            <div className="mt-4 md:flex md:justify-between lg:!mt-0">
              <div id="address" className="max-w-[230px] md:!max-w-[350px] ">
                <div className="md:flex">
                  <div className="mr-1">André Beckschulte Immobilien</div>
                  <div>Management</div>
                </div>
                <div>Friedrich-Ebert-Str. 76</div>
                <div>59425 Unna</div>
              </div>
              <div id="contact" className="mt-4 md:!mt-0 lg:ml-16">
                <div
                  id="contact-telephone"
                  className="mb-2 cursor-pointer"
                  onClick={() => window.open(`tel:${phoneNumber}`, '_self')}
                >
                  Telefon: 02303 / 94 237 - 0
                </div>
                <div id="contact-email" className="">
                  <a href="mailto:BIM@Beckschulte.com">Email: BIM@Beckschulte.com</a>
                </div>
              </div>
            </div>
          </div>
          <MobileFooterMenu />
          <FooterMenu />
          <div id="copy-right-and-data-privacy" className="mt-2 md:flex md:justify-between">
            <div id="copy-right" className="text-base !leading-[19px]">
              {getCopyRightText()}
            </div>
            <div id="data-privacy" className="mt-4 flex text-base !leading-[19px] md:!mt-0">
              <div className="cursor-pointer">
                <Link to={'/impressum'}>{t('imprint')}</Link>
              </div>
              <div className="text-neutral-700 mx-2 cursor-pointer">/</div>
              <div className="cursor-pointer">
                <Link to={'/datenchutz'}>{t('dataProtection')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="divider" className="bg-primary-500 h-2 w-full" />
    </footer>
  );
};

export default Footer;
