import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import './styles.css';
import './app/utils/i18n';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ScrollToTop from 'app/components/sctoll-to-top';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import '@fontsource/lexend-deca/300.css';
import '@fontsource/lexend-deca/400.css';
import '@fontsource/lexend-deca/500.css';
import '@fontsource/lexend-deca/600.css';
import '@fontsource/nothing-you-could-do';
import 'react-toastify/dist/ReactToastify.css';
import { gdprCookieNotice } from 'cookie-handler-gdpr/dist/esm';

gdprCookieNotice({
  essential: '',
  locale: 'de',
  colorPrimary: '#64BD4E',
  colorSecondary: '#ffffff',
  iconColor: '#ffffff',
  policy: 'https://beckschulte.com/datenchutz',
  settingOptions: [],
  performance: '',
  marketing: '',
  googleAnalytic: '',
  hideAfterApply: true,
});

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);
