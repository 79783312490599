import Router from './Router';

export function App() {
  return (
    <div className="font-sans">
      <Router />
    </div>
  );
}

export default App;
