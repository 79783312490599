import styled, { keyframes } from 'styled-components';

const LdsEllipsis1 = keyframes`
  0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
`;

const LdsEllipsis2 = keyframes`
 0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
`;

const LdsEllipsis3 = keyframes`
   0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
`;

const Ellipsis = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #64bd4e;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: ${LdsEllipsis1} 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: ${LdsEllipsis2} 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: ${LdsEllipsis2} 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: ${LdsEllipsis3} 0.6s infinite;
  }
`;

const Loading = () => {
  return (
    <div className="relative h-[80vh]">
      <Ellipsis>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Ellipsis>
    </div>
  );
};

export default Loading;
