import { RemoveNameHouse } from 'app/services/session';
import { ROUTE_PATH } from 'constant/utils';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const useViewModel = () => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const [isScrollDown, setIsScrollDown] = useState<boolean>(false);
  const currentLocation = useLocation();
  const { pathname } = currentLocation;
  const isTransparentTheme = currentLocation.pathname !== '/';
  const isHomePage = currentLocation.pathname === '/';

  const activeNav = useCallback(
    (navItem: string) => {
      if (!pathname.includes(navItem) && pathname !== ROUTE_PATH.HOME) {
        return true;
      }

      return false;
    },
    [pathname],
  );

  const clearHouseName = useCallback(() => {
    RemoveNameHouse('house');
  }, []);

  useEffect(() => {
    setIsNavOpen(false);

    window.onscroll = () => {
      if (window.pageYOffset !== 0) {
        setIsScrollDown(true);
      } else {
        setIsScrollDown(false);
      }
    };
  }, [currentLocation]);

  const memorize = useMemo(
    () => ({
      isNavOpen,
      setIsNavOpen,
      isScrollDown,
      isTransparentTheme,
      pathname,
      activeNav,
      isHomePage,
      clearHouseName,
    }),
    [
      isNavOpen,
      setIsNavOpen,
      isScrollDown,
      isTransparentTheme,
      pathname,
      activeNav,
      isHomePage,
      clearHouseName,
    ],
  );

  return memorize;
};
