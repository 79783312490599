import { ReactNode } from 'react';
import { BaseComponent } from '../../model';
import { ReactComponent as RefreshIcon } from 'assets/icons/phone.svg';
import { css, cx } from '@emotion/css';
import { baseColor } from 'styles/theme';

type TertiaryOutlineButtonComponent = BaseComponent & {
  children?: ReactNode;
  onClick: () => void;
  type?: 'reset' | 'submit' | 'button';
};
type TertiaryOutlineButtonIconComponent = TertiaryOutlineButtonComponent & {
  side?: 'left' | 'right';
  customIcon?: ReactNode;
};

export const TertiaryOutlineButton = ({
  className,
  children = 'Outline Button',
  onClick,
  type,
}: TertiaryOutlineButtonComponent) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={cx(
        `${className} bg-primary-500 hover:bg-primary-300 active:bg-primary-600 active:border-primary-100 active:text-primary-100 h-10 min-w-[140px] rounded-[100px] border-2 border-solid border-white px-4 py-2.5 text-sm font-semibold leading-4 text-white`,
        css`
          :hover {
            path {
              stroke: #ffffff;
            }
          }
          :active {
            path {
              stroke: ${baseColor.primary[100]};
            }
          }
        `,
      )}
    >
      {children}
    </button>
  );
};

export const TertiaryOutlineIconButton = ({
  className,
  side = 'left',
  children = 'Outline Button',
  customIcon,
  onClick,
}: TertiaryOutlineButtonIconComponent) => {
  const Icon = (className: string) => {
    return customIcon || <RefreshIcon className={className} />;
  };

  return side === 'left' ? (
    <TertiaryOutlineButton onClick={onClick} className={`${className} flex`}>
      {Icon('mr-2 text-primary-500')}
      {children ? children : 'Outline Button'}
    </TertiaryOutlineButton>
  ) : (
    <TertiaryOutlineButton onClick={onClick} className={`${className} flex`}>
      {children ? children : 'Outline Button'}
      {Icon('ml-2 text-primary-500')}
    </TertiaryOutlineButton>
  );
};
