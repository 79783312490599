import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../footer';
import Navbar from '../navbar';

const Layout = ({ children, className }: { children: ReactNode; className?: string }) => {
  const currentLocation = useLocation();
  const { pathname } = currentLocation;

  return (
    <>
      <Navbar />
      <div className={`${className} ${pathname !== '/' ? 'pt-[90px]' : ''}`}>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
