export const employeeProfile = [
  {
    name: 'Nicole Beckschulte',
    position: 'Assessorin jur.',
    image: 'assets/images/employee/nicoleBeckschulte.png',
    webp: 'assets/images/employee/nicoleBeckschulte.webp',
  },
  {
    name: 'Gerd Beckschulte',
    position: 'Dipl. Bankbetriebswirt',
    image: 'assets/images/employee/gerdBeckschulte.png',
    webp: 'assets/images/employee/gerdBeckschulte.webp',
  },
  {
    name: 'Vanessa Feldmann',
    position: 'Sekretariat',
    image: 'assets/images/employee/vanessaFeldmann.png',
    webp: 'assets/images/employee/vanessaFeldmann.webp',
  },
  {
    name: 'André Beckschulte',
    position: 'Kaufmann der Grundstücks- und Wohnungswirtschaft',
    image: 'assets/images/employee/andieBeckschulte.png',
    webp: 'assets/images/employee/andieBeckschulte.webp',
  },
  {
    name: 'Svenja Strunk',
    position: 'Immobilienkauffrau',
    image: 'assets/images/employee/svenjaStrunk.png',
    webp: 'assets/images/employee/svenjaStrunk.webp',
  },
];

export const phoneNumber = '+4902303942370';
export const email = 'BIM@Beckschulte.com';
