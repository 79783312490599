import { useViewModel } from './useViewModel';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import { ROUTE_PATH } from 'constant/utils';

interface NavbarConfig {
  title: string;
  path: string;
}

export interface NavStateConfig {
  isNavOpen: boolean;
  setIsNavOpen: (value: boolean) => void;
  isScrollDown: boolean;
  isTransparentTheme: boolean;
  pathname: string;
  activeNav: (currentNav: string) => boolean;
  isHomePage: boolean;
  clearHouseName: () => void;
}

export const navbarItems: NavbarConfig[] = [
  { title: 'Verwalten', path: ROUTE_PATH.ADMIN },
  { title: 'Vermieten', path: ROUTE_PATH.RENTAL },
  { title: 'Verkaufen', path: ROUTE_PATH.SELL },
  { title: 'Über uns', path: ROUTE_PATH.ABOUT_US },
  { title: 'Kontakt', path: ROUTE_PATH.CONTACT },
];

function Navbar() {
  const {
    isNavOpen,
    setIsNavOpen,
    isScrollDown,
    isTransparentTheme,
    pathname,
    activeNav,
    isHomePage,
    clearHouseName,
  } = useViewModel();

  const navState = {
    isNavOpen,
    setIsNavOpen,
    isScrollDown,
    isTransparentTheme,
    pathname,
    activeNav,
    isHomePage,
    clearHouseName,
  };

  return (
    <nav className="fixed z-[100] block w-full overflow-y-hidden">
      <DesktopNavbar navState={navState} />
      <MobileNavbar navState={navState} />
    </nav>
  );
}

export default Navbar;
