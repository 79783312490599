import { Routes, Route, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import { lazy } from '@loadable/component';
import Layout from './components/layout';
import Loading from './components/loading';
import { ROUTE_PATH } from 'constant/utils';

const AboutUsPage = lazy(() => import('./pages/AboutUsPage'));
const AdminPage = lazy(() => import('./pages/AdminPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const RentalPage = lazy(() => import('./pages/RentalPage'));
const SellPage = lazy(() => import('./pages/SellPage'));
const SellDetail = lazy(() => import('./sell/detail'));
const ImprintPage = lazy(() => import('./pages/ImprintPage'));
const DataPrivacy = lazy(() => import('./pages/DataPrivacyPage'));
const RentalDetail = lazy(() => import('./rental/detail'));

const Router = () => {
  return (
    <Suspense
      fallback={
        <>
          <div className="flex h-[100vh] items-center justify-center md:hidden">
            <Loading />
          </div>
          <Layout className="hidden md:block">
            <div className="h-[100vh]" />
          </Layout>
        </>
      }
    >
      <Routes>
        <Route path={ROUTE_PATH.HOME} element={<HomePage />} />
        <Route path={ROUTE_PATH.ADMIN} element={<AdminPage />} />
        <Route path={ROUTE_PATH.RENTAL} element={<RentalPage />} />
        <Route path={ROUTE_PATH.SELL} element={<SellPage />} />
        <Route path={ROUTE_PATH.ABOUT_US} element={<AboutUsPage />} />
        <Route path={ROUTE_PATH.CONTACT} element={<ContactPage />} />
        <Route path={`${ROUTE_PATH.SELL}:group/:id`} element={<SellDetail />} />
        <Route path={`${ROUTE_PATH.RENTAL}:group/:id`} element={<RentalDetail />} />
        <Route path={ROUTE_PATH.IMPRINT} element={<ImprintPage />} />
        <Route path={ROUTE_PATH.DATA_PROTECTION} element={<DataPrivacy />} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
