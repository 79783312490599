import { Link } from 'react-router-dom';
import { navbarItems, NavStateConfig } from 'components/navbar';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { BaseIconButton } from 'components/button';
import { telephoneNumber } from 'constant/utils';
import { phoneNumber } from 'constant/aboutUs';

const MobileNavbar = ({ navState }: { navState: NavStateConfig }) => {
  const { isNavOpen, activeNav, clearHouseName } = navState;
  return isNavOpen ? (
    <div id="mobile-navbar" className="flex h-screen flex-col">
      <div className="flex flex-grow flex-col gap-y-6 bg-white pt-8 pb-14 text-center md:gap-y-10 md:pt-44 lg:hidden">
        {navbarItems.map((item, index) => (
          <Link
            to={item.path}
            key={index}
            className={`${activeNav(item.path) ? 'text-gray-500' : 'text-black'}`}
            onClick={() => clearHouseName()}
          >
            {item.title}
          </Link>
        ))}
        <BaseIconButton
          onClick={() => window.open(`tel:${phoneNumber}`, '_self')}
          className="mx-auto h-14 items-center px-8 text-lg md:mt-16"
          customIcon={<PhoneIcon className="mr-2" />}
        >
          {telephoneNumber}
        </BaseIconButton>
      </div>
    </div>
  ) : null;
};

export default MobileNavbar;
